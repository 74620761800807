import React, { useState } from 'react';
import './App.css';
import irdpLogo from './ssti03.png'; // Import de l'image

function App() {
    const [age, setAge] = useState('');
    const [sexe, setSexe] = useState('');
    const [cspChoix, setCSPChoix] = useState('');
    const [typeContrat, setTypeContrat] = useState('');
    const [thesaurusFA, setThesaurusFA] = useState(false);
    const [thesaurusFC, setThesaurusFC] = useState(false);
    const [thesaurusMA, setThesaurusMA] = useState(false);
    const [thesaurusMB, setThesaurusMB] = useState(false);
    const [thesaurusDR, setThesaurusDR] = useState(false);
    const [thesaurusDS, setThesaurusDS] = useState(false);
    const [cimF, setCimF] = useState(false);
    const [cimG, setCimG] = useState(false);
    const [cimI, setCimI] = useState(false);
    const [cimM, setCimM] = useState(false);
    const [resultat, setResultat] = useState(null);
    const [error, setError] = useState(null);
    const [ageError, setAgeError] = useState('');

    const handleAgeChange = (e) => {
      const value = e.target.value;
      setAge(value);
      if (value && parseInt(value, 10) > 62) {
          setAgeError('L\'âge ne peut pas être supérieur à 62 ans.');
      } else {
          setAgeError('');
      }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setResultat(null);

        const data = {
            age: age,
            sexe: sexe,
            csp_choix: cspChoix,
            type_contrat: typeContrat,
            thesaurus_fa: thesaurusFA,
            thesaurus_fc: thesaurusFC,
            thesaurus_ma: thesaurusMA,
            thesaurus_mb: thesaurusMB,
            thesaurus_dr: thesaurusDR,
            thesaurus_ds: thesaurusDS,
            cim_f: cimF,
            cim_g: cimG,
            cim_i: cimI,
            cim_m: cimM,
        };

        try {
            const response = await fetch('http://51.91.106.211:5000/calculer_irdp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Erreur lors du calcul.');
            }

            const result = await response.json();
            setResultat(result);
        } catch (e) {
            setError(e.message);
        }
    };

    const S1 = 2.68;
    const S2 = 3.22;
    const echelleMax = 5;

    let scoreCouleur = '';
    if (resultat && resultat.score_irdp < S1) {
        scoreCouleur = 'yellow';
    } else if (resultat && resultat.score_irdp >= S1 && resultat.score_irdp < S2) {
        scoreCouleur = 'orange';
    } else if (resultat && resultat.score_irdp >= S2) {
        scoreCouleur = 'red';
    }

    return (
        <div className="App">
            <h1>Calculateur rapide du score IRDP</h1> v0.1
            <img src={irdpLogo} alt="Logo IRDP" className="irdp-logo" />
            <h5><i>Nota: Les profils de salariés suivants ne sont pas pris en compte : +62 ans, Agriculteurs, non-CDI ou non-fonctionnaire (CDD, intérim, alternant, stagiaire, contrat aidé, dirigeant non salarié, indépendant , saisonnier, Intermittent)
</i></h5>
            <form onSubmit={handleSubmit}>
                <div className="select-line"> {/* Nouveau div pour les selects et l'âge */}
                    <div className="input-group">
                        <label htmlFor="age"><b>Âge:</b></label>
                        <input type="number" id="age" value={age} onChange={handleAgeChange} required />
                        {ageError && <p className="error-message">{ageError}</p>}
                    </div>
                    <div className="input-group">
                        <label htmlFor="sexe"><b>Sexe:</b></label>
                        <select id="sexe" value={sexe} onChange={(e) => setSexe(e.target.value)} required>
                            <option value="">Sélectionner</option>
                            <option value="Femme">Femme</option>
                            <option value="Homme">Homme</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="csp_choix"><b>CSP:</b></label>
                        <select id="csp_choix" value={cspChoix} onChange={(e) => setCSPChoix(e.target.value)} required>
                            <option value="">Sélectionner</option>
                            <option value="Ni ouvrier, ni employé">Ni ouvrier, ni employé</option>
                            <option value="Employé">Employé (CSP.5)</option>
                            <option value="Ouvrier">Ouvrier (CSP.6)</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="type_contrat"><b>Contrat:</b></label>
                        <select id="type_contrat" value={typeContrat} onChange={(e) => setTypeContrat(e.target.value)} required>
                            <option value="">Sélectionner</option>
                            <option value="Titulaire de la fonction publique">Titulaire Fonction Publique</option>
                            <option value="Autre">Autre</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label><b>Thésaurus des expositions professionnelles:</b></label>
                    <label><input type="checkbox" checked={thesaurusFA} onChange={(e) => setThesaurusFA(e.target.checked)} /> Travail en force (FA)</label>
                    <label><input type="checkbox" checked={thesaurusFC} onChange={(e) => setThesaurusFC(e.target.checked)} /> Posture (FC)</label>
                    <label><input type="checkbox" checked={thesaurusMA} onChange={(e) => setThesaurusMA(e.target.checked)} /> Exigence inhérente à l'activité (MA)</label>
                    <label><input type="checkbox" checked={thesaurusMB} onChange={(e) => setThesaurusMB(e.target.checked)} /> Organisation fonctionnelle de l'activité (MB)</label>
                    <label><input type="checkbox" checked={thesaurusDR} onChange={(e) => setThesaurusDR(e.target.checked)} /> Vibration mécanique (DR)</label>
                    <label><input type="checkbox" checked={thesaurusDS} onChange={(e) => setThesaurusDS(e.target.checked)} /> Onde sonore (DS)</label><br/>
                </div>
                <div>
                    <label><b>Codification CIM-10 (première lettre):</b></label>
                    <label><input type="checkbox" checked={cimF} onChange={(e) => setCimF(e.target.checked)} /> F - Troubles mentaux, comportementaux et neurodéveloppementaux</label>
                    <label><input type="checkbox" checked={cimG} onChange={(e) => setCimG(e.target.checked)} /> G - Maladies du système nerveux</label>
                    <label><input type="checkbox" checked={cimI} onChange={(e) => setCimI(e.target.checked)} /> I - Maladies de l'appareil circulatoire</label>
                    <label><input type="checkbox" checked={cimM} onChange={(e) => setCimM(e.target.checked)} /> M - Maladies de l'appareil locomoteur et du tissu conjonctif</label>
                </div>
                <button type="submit">Calculer IRDP</button>

                {error && <p style={{ color: 'red' }}>Erreur: {error}</p>}
                {resultat && (
                    <div>
                        <div className="score-container" style={{ backgroundColor: scoreCouleur }}>
                            <strong className="score-value">{resultat.score_irdp}</strong>
                        </div>
                        <div className="indice-bar-container">
                            <div className="indice-bar">
                                <div className="zone faible">Probabilité Faible</div>
                                <div className="zone intermediaire">Probabilité Intermédiaire</div>
                                <div className="zone forte">Probabilité Forte</div>
                                <div className="threshold s1" style={{ left: `${(S1 / echelleMax) * 100}%` }}>S1 = {S1}</div>
                                <div className="threshold s2" style={{ left: `${(S2 / echelleMax) * 100}%` }}>S2 = {S2}</div>
                            </div>
                        </div>
                        <p>Interprétation: {resultat.interpretation}</p>
                    </div>
                )}
            </form>

            <div className="info-irdp">
                <h3>Informations sur l'IRDP</h3>
                <p>Seuil S1: <strong>{S1}</strong> (Sensibilité: 80%, Spécificité: 60%)</p>
                <p>Seuil S2: <strong>{S2}</strong> (Sensibilité: 56%, Spécificité: 80%)</p>
            </div>
            <div className="formule-irdp">
                <h3>Formule de calcul du score IRDP :</h3>
                <p>
                    Score IRDP = (0.05245 * Âge) + (0.339 * Sexe<sub> Femme</sub>) + (0.2483 * CSP<sub> Employé</sub>) + (0.5341 * CSP<sub> Ouvrier</sub>) - (0.4507 * Contrat<sub> FonctionPublique</sub>) + (1.1839 * CIM-10<sub> F</sub>) + (0.6277 * CIM-10<sub> G</sub>) - (0.010 * CIM-10<sub> I</sub>) + (0.6513 * CIM-10<sub> M</sub>) - (0.7678 * Thésaurus<sub> TravailForce</sub>) - (0.1571 * Thésaurus<sub> Posture</sub>) - (0.7516 * Thésaurus<sub> ExigenceActivité</sub>) - (0.0016 * Thésaurus<sub> OrganisationActivité</sub>) - (0.0008 * Thésaurus<sub> Vibration</sub>) - (0.0023 * Thésaurus<sub> OndeSonore</sub>)
                </p>
            </div>
        </div>
    );
}

export default App;